import registerStyle from "../registerPage/register.module.css";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";

export default function HolidayRegister({ _id }) {
  const [allDayFlag, setAllDayFlag] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [inicialHour, setInicialHour] = useState();
  const [finalHour, setFinalHour] = useState();
  const [message, setMessage] = useState("");
  const [selectedDay, setSelectedDay] = useState();
  const [name, setName] = useState("");

  useEffect(() => {
    async function editHoliday() {
      await api
        .get("api/espresso/holidays", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          let apiReturn = res.data;
          apiReturn = apiReturn.filter((search) => search._id == _id);
          setAllDayFlag(apiReturn[0].workSchedule.closed);
          setRepeat(apiReturn[0].recurring);
          setInicialHour(apiReturn[0].workSchedule.labelStartingTime);
          setFinalHour(apiReturn[0].workSchedule.labelEndingTime);
          setMessage(apiReturn[0].message);
          setSelectedDay(apiReturn[0].specialDay.split("T")[0]);
          setName(apiReturn[0].name);
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }

    if (!!_id) {
      editHoliday();
    }
  }, []);

  async function submitForm(event) {
    event.preventDefault();

    let body = {
      name: name,
      specialDay: selectedDay,
      recurring: repeat,
      message: message,
      workSchedule: {
        startingHour: !allDayFlag ? inicialHour.split(":")[0] : "00",
        startingMinute: !allDayFlag ? inicialHour.split(":")[1] : "00",
        labelStartingTime: !allDayFlag ? inicialHour : "00:00",
        labelEndingTime: !allDayFlag ? finalHour : "23:59",
        endingHour: !allDayFlag ? finalHour.split(":")[0] : "23",
        endingMinute: !allDayFlag ? finalHour.split(":")[1] : "59",
        closed: allDayFlag,
      },
    };

    if (!!_id) {
      Object.assign(body, { _id: _id });

      await api
        .put("api/espresso/holidays", body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          toast.success("Data editada com sucesso");
          reload(3000);
        })
        .catch(() => {
          toast.error("Algo deu errado durante o cadastro");
        });
    } else {
      await api
        .post("api/espresso/holidays", body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          toast.success("Data cadastrada com sucesso");
          reload(3000);
        })
        .catch(() => {
          toast.error("Algo deu errado durante o cadastro");
        });
    }
  }

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
      },
      timer ? timer : 2000
    );
  }

  return (
    <div className={registerStyle.containerModal}>
      <form
        onSubmit={(e) => {
          submitForm(e);
        }}
      >
        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-1">
            <label className="defaultLabel">Nome:</label>
          </div>
          <input
            value={name}
            type="text"
            className="input-bootstrap"
            onChange={(e) => setName(e.target.value)}
            placeholder="Ex: feriados nacionais"
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel">
            <label className="defaultLabel">Data:</label>
          </div>
          <input
            className="input-bootstrap"
            value={selectedDay}
            type="date"
            onChange={(e) => setSelectedDay(e.target.value)}
          />
        </div>

        <div className=" d-flex flex-column align-items-center mb-3">
          <div className="containerLabe\">
            <label className="defaultLabel">Feriado Recorrente?</label>
          </div>
          <label
            htmlFor="repeat"
            className={`holyday${repeat ? "Unchecked" : "Checked"}`}
          >
            <a className="holydayFont pointer hover-text">
              {" "}
              {repeat ? "Sim" : "Não"}{" "}
            </a>
          </label>
          <input
            value={repeat}
            className={registerStyle.specific}
            type="checkbox"
            id="repeat"
            onChange={(e) => setRepeat(e.target.checked)}
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-1">
            <label className="defaultLabel">Mensagem</label>
          </div>
          <textarea
            className="text-area-bootstrap"
            placeholder="Ex: Olá, hoje é feriado nacional, por isso não estamos atendendo, retornaremos seu contato no proximo dia util"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        {!allDayFlag ? (
          <div className="">
            <div className="d-flex flex-column">
              <div className={`mb-3 d-flex align-items-center`}>
                <label className="defaultLabel me-2">Hora Inicial:</label>
                <div className={registerStyle.containerTime}>
                  <input
                    className="time-bootstrap"
                    value={inicialHour}
                    type="time"
                    onChange={(e) => setInicialHour(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3 d-flex align-items-center">
                <label className="defaultLabel me-3">Hora Final:</label>
                <div className={registerStyle.containerTime}>
                  <input
                    className="time-bootstrap"
                    value={finalHour}
                    type="time"
                    onChange={(e) => setFinalHour(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="d-flex justify-content-center flex-column align-items-center mb-3">
          <div className="hibernationAlign">
            <label className="defaultLabel">Ura esta aberta</label>
          </div>
          <label
            htmlFor="allDay"
            className={`holyday${allDayFlag ? "Checked" : "Unchecked"}`}
          >
            <a className="holydayFont pointer hover-text">
              {" "}
              {allDayFlag ? "Não" : "Sim"}{" "}
            </a>
          </label>
          <input
            value={allDayFlag}
            className={registerStyle.specific}
            type="checkbox"
            id="allDay"
            onChange={(e) => setAllDayFlag(e.target.checked)}
          />
          {console.log(allDayFlag)}
        </div>

        <div className="d-flex justify-content-center">
          <button className="btn btn-primary containerBtn-4" type="submit">
            {" "}
            Salvar{" "}
          </button>
        </div>
      </form>
    </div>
  );
}
