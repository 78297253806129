import * as React from "react";
import DataTable from "react-data-table-component";
import ChatBallon from "../../pages/history/ChatBallon";
import "./table.css";
import SubHeaderComponent from "./newTableComponents/subHeaderComponent";

function organizePerData(conversations) {
  const results = {};
  conversations.forEach((conversation) => {
    const data = conversation.data.split(" ")[0];

    if (!results[data]) {
      results[data] = [];
    }

    results[data].push(conversation);
  });
  return convertObjectToArray(results);
}

function formatData(data) {
  const arrData = data.split("-");

  return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
}

function convertObjectToArray(objeto) {
  let id = 1;
  return Object.entries(objeto).map(([data, conversas]) => ({
    id: id++,
    data,
    conversas,
  }));
}

const colum = [
  {
    name: "Data",
    selector: (row) => formatData(row.data),
    sortable: true,
  },
];

const styles = {
  header: {
    style: {
      fontSize: "22px",
      color: "#fff",
      backgroundColor: "#2980B9",
      minHeight: "56px",
      paddingLeft: "16px",
      paddingRight: "8px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#2980B9",
      minHeight: "52px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      color: "#fff",
    },
  },
  pagination: {
    style: {
      color: "#fff",
      fontSize: "13px",
      minHeight: "56px",
      backgroundColor: "#2980b9",
    },
    pageButtonsStyle: {
      borderRadius: "50%",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "px",
      cursor: "pointer",
      transition: "0.4s",
      color: "#fff",
      fill: "#fff",
      backgroundColor: "transparent",
    },
  },
};
const paginationComponentOptions = {
  rowsPerPageText: "Atendimentos por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

// @ts-ignore
function handleSort(rows, collumn, order) {
  if (order == "desc") {
    return rows.reverse();
  }
  return rows;
}

function filterPerDate(date, elem) {
  const response = [];
  for (let el of elem) {
    let formated = formatData(el.data);
    if (formated.includes(date)) {
      response.push(el);
    }
  }
  return response;
}

function filterPerMessage(query, list) {
  query = query.toLowerCase();
  let response = [];
  for (const elem of list) {
    for (let conversation of elem.conversas) {
      let text = conversation.texto.toLowerCase();
      if (text.includes(query)) {
        response.push(elem);
        break;
      }
    }
  }
  return response;
}

export default function HistoryTable({ history }) {
  let organizedList = organizePerData(history);
  const [query, setQuery] = React.useState("");
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    organizedList = organizePerData(history.reverse()); // para ao contrario adicionar history.reverse()

    if (!query.length) {
      // @ts-ignore
      // @ts-ignore
      setData(organizedList);
    }
  }, [history]);

  function filterFunction(query, type) {
    let list = organizedList;
    let q = "";
    if (query.length) {
      if (type === "date") {
        list = filterPerDate(query, organizedList);
      } else {
        q = query;
        list = filterPerMessage(query, organizedList);
      }
    }
    setQuery(q);
    // @ts-ignore
    setData(list);
  }

  function rowPreExpanded(row) {
    return row.id == 1 ? true : false;
  }
  const ExpandedComponent = ({ data }) => (
    <>
      <ChatBallon conversations={data} query={query} />
    </>
  );
  

  return (
    <div
      style={{ height: "94%", width: "100%", overflow: "scroll" }}
    >
      <SubHeaderComponent onFilter={filterFunction} />
      <DataTable
        customStyles={styles}
        keyField="TableGenesys"
        dense
        columns={colum}
        data={data}
        sortFunction={handleSort}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableRowExpanded={rowPreExpanded}
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
}
