import { config } from "../utils.js";

export const registerMenus = [
  {
    key: "tabulacoes",
    label: "Tabulações",
    route: "/app/wrapup",
  },
  {
    key: "registerHibernation",
    label: "Hibernação",
    route: "/app/hibernation",
  },
  {
    key: "templates",
    label: "Templates",
    route: "/app/messages",
  },
  {
    key: "words",
    label: "Palavras",
    route: "/app/tags",
  },
];

export const hasRegisterItems = registerMenus.some(
  (item) => config.application.menus?.[item.key]
);
