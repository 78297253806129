import { WebStorageStateStore } from "oidc-client-ts";

export default {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: "https://login.espressolw.com",
  client_id: "653ac574ce931c0584602e91",
  redirect_uri: new URL("/callback.html", document.location.href).href,
  automaticSilentRenew: true,
  silent_redirect_uri: new URL("/silent-renew.html", document.location.href)
    .href,
  response_type: "code",
  scope: "openid offline_access api",
  post_logout_redirect_uri: new URL("/", document.location.href).href,
  filterProtocolClaims: true,
};
