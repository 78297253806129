import React from "react";
import "./subHeaderComponent.css";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SubHeaderComponent({ onFilter }) {
  const handleChange = (event) => {
    setInput(event.target.value);
    onFilter(input, type);
  };
  // const openFilter = () => {
  //   onFilter("");
  //   setShowInput(!showInput);
  // };
  const cleanFilter = (event) => {
    setInput("");
    onFilter("", type);
  };
  // const [showInput, setShowInput] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [type, setType] = React.useState("message");
  const [placeholder, setPlaceholder] = React.useState("Digite a mensagem");
  const handleChangeSelect = (event) => {
    setType(event.target.value);
    if (event.target.value == "date") {
      setPlaceholder("Digite a data");
    } else {
      setPlaceholder("Digite a mensagem");
    }
  };

  return (
    <div className="filter-container">
      {/* {!showInput ? (
        <button onClick={openFilter} className={"btn-no-borders"}>
          <FilterAltIcon fontSize={"small"} />
          <p>Filtrar</p>
        </button>
      ) : ( */}
      <div className={"search-container"}>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            maxHeight: 100,
            color: "#fff",
            border: "none",
            boxShadow: "none",
            "&:focus": {
              borderRadius: 4,
              borderColor: "#fff",
              boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
          }}
          size="small"
        >
          {/* <InputLabel
            sx={{ color: "#fff", fontSize: "14px" }}
            id="demo-select-small-label"
          >
            Tipo
          </InputLabel> */}
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={type}
            label="Tipo"
            onChange={handleChangeSelect}
            sx={{ color: "#fff", height: "30px", fontSize: "12px" }}
          >
            <MenuItem sx={{ fontSize: "12px" }} value={"date"}>
              Data
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value={"message"}>
              Mensagem
            </MenuItem>
          </Select>
        </FormControl>
        <input
          className={"input-search"}
          placeholder={placeholder}
          value={input}
          onChange={handleChange}
        />
        <button className={"btn-search"}>Pesquisar</button>
        <button onClick={cleanFilter} className="btn-no-borders">
          <CloseIcon className={"icon-close"} />
        </button>
      </div>
    </div>
  );
}
