import "./genesys.css";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import NewGenesysTable from "../../components/tables/newGenesysTable";
import api from "../../services/api";
import { toast } from "react-toastify";
import { config, PURECLOUD_URL } from "../../services/utils";
import GenesysModal from "./historyFunctions/modalGhistory";
import SendHSMModal from "./historyFunctions/senHSMModal";
import MidiaSender from "./historyFunctions/sendMidia";
import AddTab from "./historyFunctions/addTab";
import EmojiSelector from "./historyFunctions/historyEmoji/popOverEmoji";
import MicIcon from "@mui/icons-material/Mic";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Button } from "@mui/material";
import ParamLoading from "../../components/loadParams/loadingParams";
import HSM from "../screenHSM/screenHSM";
import MailIcon from "@mui/icons-material/Mail";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import VerifyFields from "./historyFunctions/verifyFields/verifyFields";

export default function GenesysHistory({ match }) {
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [endingReturn, setEndingReturn] = useState(false);
  const [garantia, setGarantia] = useState(false);
  const [exec, setExec] = useState([true]);
  const [participantData, setParticipantData] = useState({});

  const {
    gHistoryMidia,
    gHistoryTabulacao,
    gHistoryEmojo,
    gHistoryEndingInteraction,
    gHistoryHSM,
    garantiaAssistida,
  } = config.application.features;

  const { audioRecorder } = config.application.menus;

  const telefone = match.params.telefone;
  const origin = match.params.origin;
  const token = match.params.token;

  useEffect(() => {
    const loadTables = async () => {
      await api
        .get(`api/espresso/${telefone}/${origin}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((res) => {
          if (res.data.length === 0) {
            toast.error(`O número ${telefone} não tem registros`);
          }
          setTimeout(() => {
            setExec(!exec);
          }, 10000);
          setRetornoAPI(res.data);
          return;
        })
        .catch((err) => {
          setTimeout(() => {
            setExec(!exec);
          }, 10000);
        });
      return;
    };
    loadTables();
  }, [exec]);

  useEffect(() => {
    let conversationID = token.split(";");

    async function loadParticipantData() {
      await api
        .get(`${PURECLOUD_URL}/conversations/messages/${conversationID[0]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
          },
        })
        .then((res) => {
          setParticipantData(res.data.participants[0].attributes);
          setGarantia(
            res.data.participants[0].attributes.garantiaAssistida
              ? res.data.participants[0].attributes.garantiaAssistida
              : false
          );
        });
    }

    loadParticipantData();
  }, []);

  async function endingInteraction() {
    setEndingReturn(false);
    let payload = {
      phone: telefone,
      origin: origin,
    };

    await api
      .post("api/espresso/disconnect", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setEndingReturn(true);
        // toast.warn("Interação encerrada com sucesso");
        return;
      })
      .catch((err) => {
        setEndingReturn(true);
        toast.error("Erro #006: Não foi possível encerrar a interação");
      });
  }

  return (
    <div className="spacement">
      <div className="buttonBar">
        <div className="buttons">
          {gHistoryMidia ? (
            <div className="separator">
              <GenesysModal
                buttonString={
                  <div className="button-Z">
                    <CloudUploadIcon sx={{ fontSize: "calc(6px + 1.5vw)" }} />
                    <span>enviar</span>
                  </div>
                }
                boxName="Enviar Midias"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <MidiaSender />
              </GenesysModal>
            </div>
          ) : null}

          {gHistoryTabulacao ? (
            <div className="separator">
              <GenesysModal
                buttonString={
                  <div className="button-Z">
                    <ViewListOutlinedIcon
                      sx={{
                        fontSize: "calc(6px + 1.5vw)",
                        marginRight: "0.5vw",
                      }}
                    />
                    tabulação
                  </div>
                }
                boxName="Adicionar Tabulação"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <AddTab />
              </GenesysModal>
            </div>
          ) : null}

          {audioRecorder ? (
            <div className="separator">
              <Button
                variant="string"
                onClick={() => {
                  window.open(
                    `${window.location.origin}/app/recordingAudio/${telefone}/${origin}/${token}`,
                    "childWindow",
                    "width=350,height=120"
                  );
                }}
                sx={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <div className="button-Z">
                  <MicIcon
                    sx={{ fontSize: "calc(6px + 1.5vw)", marginRight: "0.5vw" }}
                  />
                  Gravar
                </div>
              </Button>
            </div>
          ) : null}

          {gHistoryHSM ? (
            <div className="separator">
              <SendHSMModal
                buttonString={
                  <div className="button-Z">
                    <MailIcon
                      sx={{
                        fontSize: "calc(6px + 1.5vw)",
                        marginRight: "0.5vw",
                      }}
                    />
                    HSM
                  </div>
                }
                boxName="Enviar HSM"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <HSM showAgendamento={true} />
              </SendHSMModal>
            </div>
          ) : null}

          {garantia ? (
            <SendHSMModal
              buttonString={
                <div className="button-Z">
                  <RequestQuoteIcon
                    sx={{ fontSize: "calc(6px + 1.5vw)", marginRight: "0.5vw" }}
                  />
                  Garantia
                </div>
              }
              boxName="Solicitar Garantia Assistida"
              className="send"
              styleButton={{
                bgcolor: "#2980B9",
                color: "white",
                fontSize: "calc(6px + 0.7vw)",
                borderRadius: 2,
                width: "calc(12px + 11vw)",
                borderSize: 0,
                ":hover": { bgcolor: "#60bfff" },
              }}
            >
              <VerifyFields
                URL={`${window.location.origin}/app/ghistory/${telefone}/${origin}`}
                participantData={participantData}
              />
            </SendHSMModal>
          ) : null}
          {gHistoryEmojo ? (
            <div className="separator">
              <EmojiSelector />
            </div>
          ) : null}
        </div>

        {gHistoryEndingInteraction ? (
          <div className="rightButtons">
            {endingReturn ? (
              <Button
                variant="string"
                onClick={() => {
                  endingInteraction();
                }}
                sx={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 11vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <div className="button-Z">Encerrar</div>
              </Button>
            ) : (
              <ParamLoading />
            )}
          </div>
        ) : null}
      </div>
      <div className="genesysBox">
        <StyledEngineProvider injectFirst>
          {/* <GenesysTable
            history={data}
            styleButton={{ bgcolor: "black", color: "white" }}
            style={{ height: 100 }}
          ></GenesysTable> */}
          <NewGenesysTable history={retornoAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
