import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  PURECLOUD_URL,
  config,
  CRM_URL,
  defineLoginGenesys,
} from "../../../services/utils";
import { v4 as UwUid } from "uuid";
import { AttachFile } from "@mui/icons-material";
import modalHSMStyle from "./modalHSM.module.css";
import ParamLoading from "../../../components/loadParams/loadingParams";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ActionBtn from "../../../components/UI/actionbtn";
import TablePreview from "../previewTable/preview";
import * as XLSX from "xlsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ConfirmationModal from "../../../components/UI/Modals/ConfirmationModal.jsx";
import CheckIcon from "@mui/icons-material/DoneAllOutlined";

export default function ModalHSM() {
  // ---------------- Constantes ----------------- //
  const oneSenderEntry =
    config.fakeConfig.defaultBroker !== "disable"
      ? config.fakeConfig.brokers[config.fakeConfig.defaultBroker].senders
      : [];

  const [nameMailing, setNameMailing] = useState("");
  const [codeMailingBKP, setCodeMailingBKP] = useState();
  const [sender, setSender] = useState({});
  const [queues, setQueues] = useState([]);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [paramsDefined, setParamsDefined] = useState(false);
  const [params, setParams] = useState([]);
  const [excelData, setExcelData] = useState({});
  const [dataParam, setDataParam] = useState({});
  const [open, setOpen] = useState(false);
  const [callAPIOnline, setCallAPIOnline] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [step, setStep] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  });
  const [data, setData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [totalLines, setTotalLines] = useState(0);
  const [batchNumber, setBatchNumber] = useState("1");
  const [batchTimer, setBatchTimer] = useState("5");

  const timerOptions = [
    { value: "5", label: "00:05" },
    { value: "10", label: "00:10" },
    { value: "15", label: "00:15" },
    { value: "20", label: "00:20" },
    { value: "25", label: "00:25" },
    { value: "30", label: "00:30" },
    { value: "35", label: "00:35" },
    { value: "40", label: "00:40" },
    { value: "45", label: "00:45" },
    { value: "50", label: "00:50" },
    { value: "55", label: "00:55" },
    { value: "60", label: "01:00" },
  ];

  const stepsVia = [
    "Defina configurações",
    "Tratar números repetidos",
    "Tratar números inválidos",
    "Tratar números já enviados",
    "Enviar",
  ];

  const steps = [
    "Defina configurações",
    "Tratar números repetidos",
    "Tratar números inválidos",
    "Enviar",
  ];

  // if (messages.length == 0 && config.fakeConfig.defaultBroker !== 'disable') {
  //   getMessages(JSON.stringify({ numberSelected: oneSenderEntry[0], broker: config.fakeConfig.defaultBroker }))
  // }
  // --------------------------------------------- //

  // ----------------- onLoad -------------------- //
  const isLoginGenesys = defineLoginGenesys();

  useEffect(() => {
    if (isLoginGenesys) {
      api
        .get(
          `${PURECLOUD_URL}/users/${localStorage.getItem("userId")}/queues`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.entities.length == 0) {
            toast.warn("Usuario não possui filas cadastradas");
          } else {
            setQueues(res.data.entities);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.code == "authentication.required" ||
            error?.response?.data?.code == "bad.credentials"
          ) {
            toast.warn("Sessão do genesys expirada");
            localStorage.removeItem("pcToken");
            reload(3000);
          } else {
            toast.error("Filas não encontradas");
          }
        });
    } else {
      let queues = api
        .get(`${CRM_URL}/queue`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setQueues(res.data.results);
        });
    }
    if (messages.length == 0 && config.fakeConfig.defaultBroker !== "disable") {
      getMessages(
        JSON.stringify({
          numberSelected: oneSenderEntry[0],
          broker: config.fakeConfig.defaultBroker,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      selectedMessage &&
      excelData &&
      excelData.columns &&
      selectedMessage.parametros
    ) {
      const matchingColumns = Object.keys(selectedMessage.parametros).map(
        (paramKey) => {
          return excelData.columns.find(
            (column) =>
              column.toLowerCase() ===
              selectedMessage.parametros[paramKey].toLowerCase()
          );
        }
      );
      for (const field in matchingColumns) {
        if (matchingColumns[field]) {
          handleParam(parseInt(field) + 1, matchingColumns[field]);
        }
      }
    }
  }, [selectedMessage]);
  useEffect(() => {
    if (excelData?.contacts?.length) {
      setTotalLines(excelData.contacts.length);
    }
  }, [excelData]);

  // --------------------------------------------- //

  // ----------------- Functions ----------------- //

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
        setLoadingSend(false);
      },
      timer ? timer : 500
    );
  }

  async function removeSent() {
    try {
      const response = await api.delete(
        `api/espresso/mailing2/sent/${codeMailingBKP}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data);
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  async function ignoreSent() {
    try {
      const response = await api.post(
        `api/espresso/mailing2/sent/${codeMailingBKP}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data);
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  async function getInvalid() {
    try {
      const response = await api.get(
        `api/espresso/mailing2/invalids/${codeMailingBKP}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.invalidPhones);
      }
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  async function submitList() {
    setLoadingSend(true);
    try {
      const response = await api.post(
        `api/espresso/mailing2/send/${codeMailingBKP}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status == 200) {
        toast.success("Lista criada com sucesso!");
        if (response.data.ok == "nok") {
          toast.warn("Um ou mais números encontram-se em atendimento");
        }
      }
    } catch (error) {
      handleError(error);
    }
    reload(2000);
  }

  async function removeDuplicates() {
    try {
      const response = await api.delete(
        `api/espresso/mailing2/duplicates/${codeMailingBKP}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data.invalidPhones);
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  async function removeInvalids() {
    try {
      const response = await api.delete(
        `api/espresso/mailing2/invalids/${codeMailingBKP}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data.sentYesterdayList);
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  async function ignoreInvalids() {
    try {
      const response = await api.post(
        `api/espresso/mailing2/invalids/${codeMailingBKP}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data.sentYesterdayList);
    } catch (error) {
      handleError(error);
      reload(3500);
    } finally {
      setLoadingSend(false);
    }
  }

  function handleError(error) {
    toast.error(
      (error.response && error.response.data.errmsg) ||
        "Erro ao executar a ação"
    );
  }

  function restartPage() {
    if (page > 0) {
      setPage(0);
    }
  }
  async function goToStep(steps, step4) {
    setLoadingSend(true);
    const handleStepActionVia = async (action) => {
      switch (action) {
        case "step2":
          restartPage();
          await handleSubmit().finally(() => {
            setLoadingSend(false);
          });
          break;
        case "step3":
          restartPage();
          if (isCancel) {
            setIsCancel(false);
            await getInvalid();
          } else {
            await removeDuplicates();
          }
          break;
        case "step4":
          restartPage();
          if (isCancel || step4) {
            setIsCancel(false);

            await ignoreInvalids();
          } else {
            await removeInvalids();
          }
          break;
        case "step5":
          restartPage();
          if (isCancel) {
            setIsCancel(false);
            await ignoreSent();
          } else {
            await removeSent();
          }
          break;

        default:
          break;
      }
    };

    const handleStepAction = async (action) => {
      switch (action) {
        case "step2":
          await handleSubmit().finally(() => {
            setLoadingSend(false);
          });
          break;
        case "step3":
          if (isCancel) {
            setIsCancel(false);
            await getInvalid();
          } else {
            await removeDuplicates();
          }
          break;
        case "step4":
          if (isCancel) {
            setIsCancel(false);
            await ignoreInvalids();
          } else {
            await removeInvalids();
          }
          break;

        default:
          break;
      }
    };

    config.application.modules.sendList
      ? await handleStepActionVia(steps)
      : await handleStepAction(steps);

    const _step = Object.fromEntries(
      Object.keys(step).map((key) => [key, false])
    );
    _step[steps] = true;

    setActiveStep(activeStep + 1);
    setStep(_step);
  }

  function disabledButton() {
    return !nameMailing || !selectedMessage || !paramsDefined || loadingSend
      ? true
      : false;
  }

  const handleParam = (field, value) => {
    let _dataParam = dataParam;
    if (value.length > 0) _dataParam[field] = value;
    else delete _dataParam[field];
    setDataParam(_dataParam);

    setParamsDefined(
      Object.keys(selectedMessage?.parametros).length ==
        Object.keys(_dataParam).length
    );
    setForceUpdate(true); // Para o proximo sofredor: Isso é uma maldita gambiarra pro react atualizar os dados do select em tempo real e não esperar o proximo ciclo de atualização, até o presente momento essa bosta de react é movida a gambiarra.
    setTimeout(() => {
      setForceUpdate(false);
    }, 1);
  };

  async function getMessages(params) {
    try {
      const paramsParsed = JSON.parse(params);
      const numberSelected = paramsParsed.numberSelected;

      const response = await api.get("api/espresso/mensagens/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const messages = response.data.filter((msg) => {
        const selectedNamespaces = "" + numberSelected.namespaces;
        const isNamespaceMatch =
          selectedNamespaces == msg.namespace ||
          numberSelected.namespaces === "*" ||
          (msg.namespace === "*" && msg.broker === paramsParsed.broker);
        return isNamespaceMatch;
      });

      setCallAPIOnline(true);
      setMessages(messages);
    } catch (err) {
      setCallAPIOnline(false);
      if (callAPIOnline) {
        toast.error("Erro #003: Falha no acesso ao banco de dados");
      }
      setTimeout(() => getMessages(params), 20000);
    }
  }

  const fileClick = (e) => {
    document.getElementById("file-input").click();
  };

  const convertToJson = (csv) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = ("" + lines[0]).replace(/\./g, "").split("|");

    for (var i = 1; i < lines.length; i++) {
      var nullline = true;
      var obj = {};
      var currentline = lines[i].split("|");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
        if (!!currentline[j] && ("" + currentline[j]).length > 0)
          nullline = false;
      }

      if (!nullline) result.push(obj);
    }
    return result;
  };

  const handleUploadXLSX = (value) => {
    setPage(0);
    let _excelData = excelData;
    const file = value.files[0];

    if (!file) throw new Error("Nenhum arquivo carregado");
    else {
      _excelData.filemailing = file.name;
      setExcelData({ ..._excelData });

      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {
          type: "binary",
          cellText: false,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        Object.keys(ws).forEach(function (s) {
          if (ws[s].t === "n") {
            ws[s].w = ws[s].v;
          }
        });

        const data = XLSX.utils.sheet_to_csv(ws, {
          FS: "|",
          header: 1,
          raw: false,
          dateNF: "yyyy-mm-dd hh:mm:ss",
        });

        _excelData.contacts = convertToJson(data);
        _excelData.columns = Object.keys(_excelData.contacts[0]);

        let phoneCol = null;
        let agendaCol = null;
        for (const column of _excelData.columns) {
          phoneCol =
            ("" + column).toLowerCase().includes("telefone") ||
            ("" + column).toLowerCase().includes("phone")
              ? column
              : phoneCol;
          agendaCol =
            ("" + column).toLowerCase().includes("agenda") ||
            ("" + column).toLowerCase() === "schedule"
              ? column
              : agendaCol;
        }
        for (let line of _excelData.contacts) {
          let hasPhone = false;
          for (let column of _excelData.columns) {
            hasPhone =
              hasPhone ||
              (phoneCol == column &&
                !!line[column] &&
                ("" + line[column]).length > 11 &&
                ("" + line[column]).length < 14);
          }
          line.codecontact = UwUid();
          line.phonecontact = "+" + line[phoneCol];
          line.activecontact = hasPhone;
          line.createdate = new Date();
          line.sent = false;
          line.status = hasPhone ? "new" : "error";
          line.detailmensagem = hasPhone
            ? "novo"
            : "nenhum telefone para enviar.";
        }

        setExcelData({ ..._excelData });

        toast.success(`O arquivo foi carregado!`);
      };
      reader.readAsBinaryString(file);
    }
  };

  function handleMessage(value) {
    setSelectedMessage(value);
    setDataParam({});
    switchLoading(true);
    if (value?.parametros) {
      setParamsDefined(Object.keys(value.parametros).length === 0);
    } else {
      setParamsDefined(true);
    }
  }

  function switchLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function confirmAction() {
    const stepMapVia = {
      0: "step2",
      1: "step3",
      2: "step4",
      3: "step5",
    };

    const stepMap = {
      0: "step2",
      1: "step3",
      2: "step4",
    };

    const nextStep = config.application.modules.sendList.stepSent
      ? stepMapVia[activeStep]
      : stepMap[activeStep];

    if (nextStep) {
      setOpenModalConfirm(false);
      goToStep(nextStep);
    }
  }

  async function handleSubmit() {
    let _sender = sender;
    if (oneSenderEntry.length === 1) {
      _sender = {
        numberSelected: oneSenderEntry[0],
        broker: config.fakeConfig.defaultBroker,
      };
    }

    let oldPayload = {
      sender: _sender.numberSelected.mediaId,
      senduser: localStorage.getItem("userName"),
      origin: _sender.numberSelected.origin,
      codemailing: UwUid(),
      filemailing: excelData.filemailing,
      namemailing: nameMailing,
      typemensagem: "HSM",
      broker: _sender.broker,
      cdmessage: selectedMessage.codemessage,
      body: selectedMessage.body,
      param: null,
      data: {
        namespace: selectedMessage.namespace,
        elementname: selectedMessage.elementname,
        body: selectedMessage.body,
        param: dataParam,
      },
      parametros: selectedMessage?.parametros || {},
      contacts: excelData.contacts,
      columns: excelData.columns,
      batchNumber: batchNumber,
      batchTimer: batchTimer,
    };

    setCodeMailingBKP(oldPayload.codemailing);

    await api
      .post("api/espresso/mailing2", oldPayload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // toast.success("Lista criada com sucesso!");
        // if (res.data.ok == "nok") {
        //   toast.warn("Um ou mais números encontram-se em atendimento");
        // }

        setData(res.data.duplicateValues);
      })
      .catch((err) => {
        toast.error(
          (err.response && err.response.data.errmsg) ||
            "Erro ao executar a ação"
        );
      });
  }
  // --------------------------------------------- //

  return (
    <div className={modalHSMStyle.modalScreen}>
      <ConfirmationModal
        open={openModalConfirm}
        handleClose={() => {
          setOpenModalConfirm(false);
          setIsCancel(false);
        }}
        handleConfirm={() => {
          confirmAction();
        }}
        message={typeModal}
      />
      <Stepper className="mt-n4" activeStep={activeStep} alternativeLabel>
        {config.application.modules.sendList.stepSent
          ? stepsVia.map((label, index) => (
              <Step key={index}>
                <StepLabel>
                  <span style={{ fontSize: "12px" }}>{label}</span>
                </StepLabel>
              </Step>
            ))
          : steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>
                  <span style={{ fontSize: "12px" }}>{label}</span>
                </StepLabel>
              </Step>
            ))}
      </Stepper>
      {!excelData.filemailing ? (
        <div className="d-flex flex-column align-items-center">
          {" "}
          Suba seus contatos através de um arquivo Excel
          <div className="mt-3" onClick={(e) => fileClick(e)}>
            <ActionBtn
              color="green"
              icon={<AttachFile style={{ color: "white" }} />}
              text="Escolher Lista"
            />
          </div>
          <input
            id="file-input"
            type="file"
            accept=".application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls,.xlsx"
            onChange={(e) => handleUploadXLSX(e.target)}
            hidden
          />
        </div>
      ) : null}

      {excelData.filemailing && step.step1 ? (
        <div className={modalHSMStyle.entireModal}>
          <div className="onScreenHSM">
            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Nome da Lista</label>
              </div>
              <input
                required
                className={`input-bootstrap ${modalHSMStyle.maxInput}`}
                onChange={(e) => setNameMailing(e.target.value)}
                placeholder="Ex: Lista de vendas"
              />
            </div>

            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Número do remetente</label>
              </div>
              <select
                disabled={oneSenderEntry.length === 1 ? true : false}
                className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                id="register__profile"
                required
                onChange={(e) => {
                  setSender(JSON.parse(e.target.value));
                  getMessages(e.target.value, e.target);
                }}
              >
                {oneSenderEntry.length === 1 ? (
                  <option value={oneSenderEntry[0].numero}>
                    {oneSenderEntry[0].numero}
                  </option>
                ) : (
                  <option defaultValue="" hidden>
                    Selecione o número de envio
                  </option>
                )}
                {config.fakeConfig.defaultBroker !== "disable"
                  ? oneSenderEntry.map((value, idx) => (
                      <option
                        value={JSON.stringify({
                          numberSelected: value,
                          broker: config.fakeConfig.defaultBroker,
                        })}
                        key={idx}
                      >
                        {value.numero}
                      </option>
                    ))
                  : config.fakeConfig.enabled.map((broker) =>
                      config.fakeConfig.brokers[broker].senders.map(
                        (value, idx) => (
                          <option
                            value={JSON.stringify({
                              numberSelected: value,
                              broker: broker,
                            })}
                            key={idx}
                          >
                            {value.numero}
                          </option>
                        )
                      )
                    )}
              </select>
            </div>

            {/* {sender?.broker ?  */}
            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Mensagem</label>
              </div>
              <select
                className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                onChange={(e) => {
                  handleMessage(JSON.parse(e.target.value));
                }}
                // disabled={!sender?.numberSelected?.numero}
              >
                <option defaultValue="" hidden>
                  {" "}
                  Selecione...{" "}
                </option>
                {messages.map((item, idx) => (
                  <option key={idx} value={JSON.stringify(item)}>
                    {item.codemessage}
                  </option>
                ))}
              </select>
            </div>
            {/* : null} */}

            {/* {sender?.broker ?  */}
            <div className="onScreenTXT">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Prévia da Mensagem</label>
              </div>
              <TextareaAutosize
                // minRows={3}
                // maxRows={5}
                className="text-area-bootstrap w-75"
                placeholder="Escolha uma mensagem para carregar uma prévia"
                disabled
                value={selectedMessage !== null ? selectedMessage.body : ""}
              />
            </div>
            {/* : null} */}

            <div className="paramsZone">
              {/* {insertingParams()}  */}
              {selectedMessage &&
              excelData &&
              excelData.columns &&
              selectedMessage.parametros ? (
                !loading ? (
                  Object.keys(selectedMessage.parametros).map((item, idx) => {
                    return (
                      <div className={modalHSMStyle.paramSendList}>
                        <div className="containerLabel">
                          <label className="defaultLabel">
                            Parâmetro {idx + 1} -{" "}
                            {selectedMessage.parametros[item]}
                          </label>
                        </div>

                        <select
                          className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                          required
                          value={dataParam[idx + 1]}
                          onChange={(e) => {
                            handleParam(idx + 1, e.target.value);
                          }}
                        >
                          <option value="">Selecione...</option>
                          {Object.keys(excelData.columns).map((item, idx) => {
                            return (
                              <option key={idx} value={excelData.columns[item]}>
                                {excelData.columns[item]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  })
                ) : (
                  <ParamLoading />
                )
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column mt-3">
            <label htmlFor="sends" className="defaultLabel mb-2">
              Dividir envios:
            </label>
            <select
              id="sends"
              className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
              onChange={(e) => {
                setBatchNumber(e.target.value);
              }}
              value={batchNumber}
            >
              <option value="1"> 1 </option>
              <option value="2"> 2 </option>
              <option value="3"> 3 </option>
              <option value="4"> 4 </option>
            </select>
          </div>
          <div className="d-flex flex-column mt-3">
            <label htmlFor="timeroptions" className="defaultLabel mb-2">
              Tempo entre envios:
            </label>
            <select
              name="timeroptions"
              id="timeroptions"
              className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
              onChange={(e) => {
                setBatchTimer(e.target.value);
              }}
              value={batchTimer}
            >
              {timerOptions.map((c) => (
                <option key={`index-${c.label}`} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <h2 className={modalHSMStyle.totalContacts}>
              Total de contatos Carregados: {totalLines}
            </h2>
          </div>
          <div className="mt-4 pb-4 d-flex justify-content-center">
            <button
              onClick={() => setOpenModalConfirm(true)}
              disabled={disabledButton()}
              type="button"
              className="btn btn-primary containerBtn-4 me-5 mb-3"
            >
              Proximo passo
            </button>
          </div>
        </div>
      ) : null}
      {step.step2 ? (
        <div className={modalHSMStyle.entireModal}>
          {data.length ? (
            <>
              <TablePreview
                mailing={{
                  columns: excelData.columns,
                  contacts: data,
                }}
                page={page}
                setPage={setPage}
              />
              <h2 className={modalHSMStyle.totalContacts}>
                Total de contatos repetidos/carregados: {data.length}/
                {totalLines}
              </h2>
              <div
                style={{ width: "80%" }}
                className="d-flex align-items-center justify-content-center"
              >
                <button
                  onClick={() => {
                    setIsCancel(true);
                    setOpenModalConfirm(true);
                    setTypeModal(
                      `manter ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "repetidos" : "repetido"}`
                    );
                  }}
                  className="btn btn-danger me-4 px-5"
                  disabled={loadingSend}
                >
                  Manter repetidos
                </button>
                <button
                  disabled={loadingSend}
                  onClick={() => {
                    setOpenModalConfirm(true);
                    setTypeModal(
                      `remover ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "repetidos" : "repetido"}`
                    );
                  }}
                  className="btn btn-primary px-5"
                >
                  Apagar repetidos
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ height: "11.5rem", width: "90%" }}
                className="d-flex justify-content-center align-items-center"
              >
                Nenhum número repetido{" "}
                <CheckIcon
                  className="ms-2"
                  style={{ fontSize: "30px", color: "#2ecc71" }}
                />
              </div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-center"
              >
                <button
                  onClick={() => goToStep("step3")}
                  className="btn btn-primary px-5"
                  disabled={loadingSend}
                >
                  Próximo passo
                </button>
              </div>
            </>
          )}
        </div>
      ) : null}
      {step.step3 ? (
        <div className={modalHSMStyle.entireModal}>
          {data.length ? (
            <>
              <TablePreview
                mailing={{
                  columns: excelData.columns,
                  contacts: data,
                }}
                page={page}
                setPage={setPage}
              />
              <h2 className={modalHSMStyle.totalContacts}>
                Total de contatos inválidos/carregados: {data.length}/
                {totalLines}
              </h2>
              <div
                style={{ width: "80%" }}
                className="d-flex align-items-center justify-content-center "
              >
                <button
                  onClick={() => {
                    setIsCancel(true);
                    setOpenModalConfirm(true);
                    setTypeModal(
                      `manter ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "inválidos" : "inválido"}`
                    );
                  }}
                  disabled={loadingSend}
                  className="btn btn-danger me-2"
                >
                  Manter inválidos
                </button>
                <button
                  disabled={loadingSend}
                  onClick={() => {
                    setOpenModalConfirm(true);
                    setTypeModal(
                      `remover ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "inválidos" : "inválido"}`
                    );
                  }}
                  className="btn btn-primary"
                >
                  Apagar inválidos
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ height: "11.5rem", width: "90%" }}
                className="d-flex justify-content-center align-items-center"
              >
                Nenhum número inválido{" "}
                <CheckIcon
                  className="ms-2"
                  style={{ fontSize: "30px", color: "#2ecc71" }}
                />
              </div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-center"
              >
                <button
                  onClick={() => {
                    setIsCancel(true);

                    goToStep("step4", true);
                  }}
                  disabled={loadingSend}
                  className="btn btn-primary px-5"
                >
                  Próximo passo
                </button>
              </div>
            </>
          )}
        </div>
      ) : null}
      {step.step4 ? (
        <div className={modalHSMStyle.entireModal}>
          {data.length ? (
            <>
              <TablePreview
                mailing={{
                  columns: excelData.columns,
                  contacts: data,
                }}
                page={page}
                setPage={setPage}
              />
              <h2 className={modalHSMStyle.totalContacts}>
                Total de contatos que já foram enviados/carregados:{" "}
                {data.length}/{totalLines}
              </h2>
              <div
                style={{ width: "90%" }}
                className="d-flex align-items-center justify-content-center pb-4"
              >
                <button
                  onClick={() => {
                    setOpenModalConfirm(true);
                    setIsCancel(true);
                    setTypeModal(
                      `manter ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "já enviados" : "já enviados"}`
                    );
                  }}
                  className="btn btn-danger px-5 me-3"
                  disabled={loadingSend}
                >
                  Manter enviados
                </button>
                <button
                  onClick={() => {
                    setOpenModalConfirm(true);
                    setTypeModal(
                      `remover ${data.length > 1 ? "os" : "o"} ${data.length} ${
                        data.length > 1 ? "números" : "número"
                      } ${data.length > 1 ? "já enviados" : "já enviados"}`
                    );
                  }}
                  className="btn btn-primary px-5"
                  disabled={loadingSend}
                >
                  {" "}
                  Remover enviados
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ height: "11.5rem", width: "90%" }}
                className="d-flex justify-content-center align-items-center"
              >
                Nenhum número já enviado{" "}
                <CheckIcon
                  className="ms-2"
                  style={{ fontSize: "30px", color: "#2ecc71" }}
                />
              </div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-center"
              >
                <button
                  onClick={() => {
                    setIsCancel(true);
                    goToStep("step5");
                  }}
                  disabled={loadingSend}
                  className="btn btn-primary px-5"
                >
                  Próximo passo
                </button>
              </div>
            </>
          )}
        </div>
      ) : null}
      {step.step5 ? (
        <div className={modalHSMStyle.entireModal}>
          {data.length ? (
            <>
              <TablePreview
                mailing={{
                  columns: excelData.columns,
                  contacts: data,
                }}
                page={page}
                setPage={setPage}
              />
              <h2 className={modalHSMStyle.totalContacts}>
                Total de contatos a ser enviados/carregados: {data.length}/
                {totalLines}
              </h2>
              <div
                style={{ width: "80%" }}
                className="d-flex align-items-center justify-content-center mt-3 pb-4"
              >
                <button
                  disabled={loadingSend}
                  onClick={() => submitList()}
                  className="btn btn-primary px-5"
                >
                  Enviar
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ height: "11.5rem", width: "80 %" }}
                className="d-flex justify-content-center align-items-center"
              >
                <em>Nenhum número para enviar :(</em>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
}
